import React, { useRef, useState } from "react";
import {
  IBatchDeleteRequest,
  IInviteError,
  IInviteUsersRequest,
  IPartialUser,
  IUser,
  UserRole,
} from "../../../../models";
import { Form } from "react-bootstrap";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { CDataModalV2 } from "../../../../components/modal/CDataModalV2";
import classnames from "classnames";
import {
  deleteUsers,
  inviteUsers,
  resendInvite,
  updateUser,
} from "../../../users/UserApiCalls";
import { Label, ModalFooter, UncontrolledTooltip } from "reactstrap";
import { ToastrSuccess } from "../../../../services/toastrService";
import { useAPI } from "../../../../components/useAPI";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import TextInput from "../../../../components/form/TextInput";
import { IErrorTableRow } from "../../../../components/ErrorTable";

export enum ModalType {
  Invite,
  Edit,
  Resend,
  BatchDelete,
  Delete,
}

interface IOEMUserModal {
  modalType: ModalType | undefined;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  users: IUser[];
  setUsers: any;
  totalUsersAllowed: number;
  rowClicked: IUser | undefined;
  selectedUsers: any;
  setSelectedUsers: (newSelectedUsers: any) => void;
  setErrorTableTitle: (newErrorTableTitle: string) => void;
  setErrorTableData: (newErrorTableData: IErrorTableRow[]) => void;
  setIsProcessing: (isProcessing: boolean) => void;
}

const OEMUserModals = (props: IOEMUserModal) => {
  const api = useAPI();

  const [inviteEmailList, setInviteEmailList] = useState<string[]>([]);
  const editUserFormRef = useRef<FormikProps<any>>(null);

  async function handleAddUsers() {
    props.setIsProcessing(true);
    props.setErrorTableData([]);

    const data: IInviteUsersRequest = {};
    data.invites = inviteEmailList.map((email: string) => ({
      email: email,
      role: UserRole.OEMAdmin,
      canBeImpersonated: true,
    }));

    const response = await inviteUsers(api.callAPI, data);
    if (response) {
      if (response.failed && response.failed.length > 0) {
        generateErrorTable(response.failed);
      }

      if (response.successful && response.successful.length > 0) {
        ToastrSuccess(
          `${response.successful.length === 1 ? "Invite" : "Invites"} successfully sent`,
          `Successfully invited ${response.successful.length} ${response.successful.length === 1 ? "user" : "users"} to CData Connect Cloud.`,
        );

        const newUserList = [...props.users];
        response.successful.forEach((createdUser: IUser) => {
          newUserList.push(createdUser);
        });

        newUserList.sort((a, b) => {
          if (a && b && a.email && b.email) {
            if (a.email > b.email) {
              return 1;
            } else if (a.email < b.email) {
              return -1;
            }
          }

          return 0;
        });

        props.setUsers(newUserList);
      }
    }

    setInviteEmailList([]);
    props.setIsModalOpen(false);
    props.setIsProcessing(false);
  }

  async function handleUpdateUser(values: any) {
    const rowClicked = props.rowClicked;
    if (rowClicked == null) {
      return;
    }
    props.setIsProcessing(true);

    const data: IPartialUser = {
      firstName: values.FirstName,
      lastName: values.LastName,
      canBeImpersonated: false,
    };

    const response = await updateUser(api.callAPI, rowClicked.id, data);
    if (response) {
      const newUserList = [...props.users];
      const updatedValueIndex = newUserList.findIndex(
        (user) => user.id === rowClicked.id,
      );
      newUserList[updatedValueIndex].firstName = values.FirstName;
      newUserList[updatedValueIndex].lastName = values.LastName;
      props.setUsers(newUserList);

      ToastrSuccess(
        "User successfully saved",
        `User ${rowClicked.email} was successfully updated.`,
      );
    }

    props.setIsModalOpen(false);
    props.setIsProcessing(false);
  }

  function generateErrorTable(failedTables: IInviteError[]) {
    const errorTableData = failedTables
      .map<IErrorTableRow | undefined>((failedTable: any, index: number) => {
        if (failedTable.email && failedTable.reason) {
          switch (failedTable.reason) {
            case "INVALID_EMAIL_ADDRESS":
              return {
                name: failedTable.email,
                errorMessage: `Failed to invite ${failedTable.email}, not a valid email address.`,
              };
            case "CANNOT_INVITE_USER":
              return {
                name: failedTable.email,
                errorMessage: `Failed to invite ${failedTable.email}. Please use a different email address and try again.`,
              };
            case "EXISTING_INVITE":
              return {
                name: failedTable.email,
                errorMessage: `Failed to send invite because ${failedTable.email} has already been invited.`,
              };
            case "ERROR":
              return {
                name: failedTable.email,
                errorMessage: (
                  <>
                    Failed to invite {failedTable.email}.{" "}
                    <Label
                      id={"id" + index.toString()}
                      className="underline-learn-more"
                    >
                      Learn more
                    </Label>
                    <UncontrolledTooltip
                      placement="top"
                      target={"id" + index.toString()}
                      trigger="hover"
                    >
                      {failedTable.message}
                    </UncontrolledTooltip>
                  </>
                ),
              };
          }
        }
      })
      .filter((e): e is IErrorTableRow => e != null);

    const errorMessage =
      failedTables.length === 1
        ? "1 User Invite Failed"
        : `${failedTables.length} User Invites Failed`;
    props.setErrorTableTitle(errorMessage);
    props.setErrorTableData(errorTableData);
  }

  function updateInviteEmails(event: React.FormEvent) {
    const target = event.target as HTMLInputElement;

    let emails = target.value.split(/[,;]+/);
    emails = emails.map((email: string) => email.trim());
    emails = emails.filter(Boolean);
    setInviteEmailList(emails);
  }

  const emptyUserSlots = props.totalUsersAllowed - props.users.length;
  const isInviteListOverLimit =
    inviteEmailList.length > emptyUserSlots && props.totalUsersAllowed > -1;

  let modalTitle = "";
  let modalBody: JSX.Element | string = "";
  let modalConfirmButton = <></>;
  let modalCancelButton = <></>;

  switch (props.modalType) {
    case ModalType.Invite: {
      modalTitle = "Add Users";
      modalBody = (
        <Form
          onSubmit={(event) => {
            event.preventDefault();
          }}
        >
          Enter the email addresses of the users you would like to invite to
          CData Connect Cloud.
          <h5 className="mt-3 mb-3">Email</h5>
          <Form.Group>
            <Form.Control
              id="InviteEmailList"
              name="InviteEmailList"
              placeholder="johnsmith@company.com, janesmith@company.com,"
              onChange={(event: any) => updateInviteEmails(event)}
              className={classnames({
                "user-seat-error-focus-color": isInviteListOverLimit,
              })}
            />
          </Form.Group>
          {!isInviteListOverLimit ? (
            <div className="user-seat-validation">
              You can invite multiple users at once by separating each email
              with a comma.
            </div>
          ) : (
            <div className="user-seat-validation user-seat-error-font-color">
              You only have {emptyUserSlots} user seats available. Please delete{" "}
              {inviteEmailList.length - emptyUserSlots} email(s) or upgrade your
              subscription to add more seats to your plan.
            </div>
          )}
        </Form>
      );

      modalConfirmButton = (
        <CDataButton
          disabled={isInviteListOverLimit || inviteEmailList.length === 0}
          buttonType={ButtonType.Primary}
          type="button"
          onClick={() => handleAddUsers()}
        >
          Send Invite
        </CDataButton>
      );

      break;
    }
    case ModalType.Edit: {
      const initialFormValues = {
        FirstName: props.rowClicked?.firstName,
        LastName: props.rowClicked?.lastName,
        Email: props.rowClicked?.email,
      };

      const validationSchema = Yup.object().shape({
        FirstName: Yup.string()
          .min(1, "First name must be between 1 and 100 characters")
          .max(100, "First name must be between 1 and 100 characters")
          .required("This is a required field"),
        LastName: Yup.string()
          .min(1, "Last name must be between 1 and 100 characters")
          .max(100, "Last name must be between 1 and 100 characters")
          .required("This is a required field"),
      });

      modalTitle = "Edit User";
      modalBody = (
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={handleUpdateUser}
          innerRef={editUserFormRef}
        >
          {({ handleSubmit, dirty, isValid }) => (
            <>
              <Form onSubmit={handleSubmit}>
                <TextInput label="First Name" name="FirstName" isRequired />
                <TextInput label="Last Name" name="LastName" isRequired />
                <TextInput label="Email" name="Email" isDisabled />
              </Form>
              <ModalFooter className="mx-n3 mb-n3">
                <CDataButton
                  disabled={!dirty || !isValid}
                  buttonType={ButtonType.Primary}
                  type="button"
                  className="internal-modal-footer"
                  onClick={() =>
                    handleUpdateUser(editUserFormRef?.current?.values)
                  }
                >
                  Save Changes
                </CDataButton>
              </ModalFooter>
            </>
          )}
        </Formik>
      );

      break;
    }
    case ModalType.Resend: {
      modalTitle = "Resend Invite";
      modalBody =
        "Are you sure you want to send a new invitation email to this user?";

      modalConfirmButton = (
        <CDataButton
          buttonType={ButtonType.Primary}
          type="button"
          onClick={async () => {
            props.setIsProcessing(true);
            const response = await resendInvite(
              api.callAPI,
              props.rowClicked!.id,
            );
            if (response) {
              ToastrSuccess(
                "Invite successfully resent",
                "Successfully re-invited this user to CData Connect Cloud.",
              );
            }
            props.setIsModalOpen(false);
            props.setIsProcessing(false);
          }}
        >
          Send Invite
        </CDataButton>
      );

      modalCancelButton = (
        <CDataButton
          buttonType={ButtonType.Secondary}
          type="button"
          onClick={() => {
            props.setIsModalOpen(false);
          }}
        >
          Close
        </CDataButton>
      );

      break;
    }
    case ModalType.BatchDelete: {
      const selectedUsersCount = Object.keys(props.selectedUsers).length;
      modalTitle = selectedUsersCount === 1 ? "Delete User" : "Delete Users";
      modalBody =
        selectedUsersCount === 1
          ? "Are you sure you want to delete this user?"
          : "Are you sure you want to delete these users?";

      modalConfirmButton = (
        <CDataButton
          buttonType={ButtonType.Danger}
          type="button"
          onClick={async () => {
            props.setIsProcessing(true);
            const deleteUserRequest: IBatchDeleteRequest = {
              ids: Object.keys(props.selectedUsers).map((key) => key),
            };

            const payload = await deleteUsers(api.callAPI, deleteUserRequest);
            if (payload) {
              let successTitle: string;
              let successBody: string;
              if (payload.deletedIds?.length === 1) {
                const userId = Object.keys(props.selectedUsers)[0];
                const userName = props.users.find(
                  (user: IUser) => user.id === userId,
                )?.email;
                successTitle = "User successfully deleted";
                successBody = `User ${userName} was successfully deleted.`;
              } else {
                successTitle = "Users successfully deleted";
                successBody = `${payload.deletedIds?.length} users were successfully deleted.`;
              }

              ToastrSuccess(successTitle, successBody);

              let newUserList = [...props.users];
              newUserList = newUserList.filter((user) => {
                return !payload.deletedIds?.includes(user.id);
              });
              props.setUsers(newUserList);
              props.setSelectedUsers({});
            }

            props.setIsModalOpen(false);
            props.setIsProcessing(false);
          }}
        >
          Confirm
        </CDataButton>
      );

      modalCancelButton = (
        <CDataButton
          buttonType={ButtonType.Secondary}
          type="button"
          onClick={() => {
            props.setIsModalOpen(false);
          }}
        >
          Close
        </CDataButton>
      );

      break;
    }
    case ModalType.Delete: {
      modalTitle = "Delete User";
      modalBody = "Are you sure you want to delete this user?";

      modalConfirmButton = (
        <CDataButton
          buttonType={ButtonType.Danger}
          type="button"
          onClick={async () => {
            props.setIsProcessing(true);
            const deleteUserRequest: IBatchDeleteRequest = {
              ids: [props.rowClicked!.id],
            };

            const payload = await deleteUsers(api.callAPI, deleteUserRequest);
            if (payload) {
              ToastrSuccess(
                "User successfully deleted",
                `User ${props.rowClicked?.email} was successfully deleted.`,
              );

              let newUserList = [...props.users];
              newUserList = newUserList.filter(
                (user) => !payload.deletedIds?.includes(user.id),
              );
              props.setUsers(newUserList);
            }

            props.setIsModalOpen(false);
            props.setIsProcessing(false);
          }}
        >
          Confirm
        </CDataButton>
      );

      modalCancelButton = (
        <CDataButton
          buttonType={ButtonType.Secondary}
          type="button"
          onClick={() => {
            props.setIsModalOpen(false);
          }}
        >
          Close
        </CDataButton>
      );

      break;
    }
  }

  return (
    <CDataModalV2
      displayed={props.isModalOpen}
      close={() => {
        props.setIsModalOpen(false);
      }}
      title={modalTitle}
      spacedFooter={false}
      primaryButton={modalConfirmButton}
      secondaryButton={modalCancelButton}
    >
      {modalBody}
    </CDataModalV2>
  );
};

export default OEMUserModals;
